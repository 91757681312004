* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system-, BlinkMacSystemFont-, Helvetica, Inter, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.4;
  color: hsl(220, 20%, 40%);
  font-size: 15px;
}

a {
  text-decoration: none;
  color: hsl(249, 91%, 58%);
}

p {
  margin: 0 0 .5rem;
}

h1, h2 {
  line-height: 1.1;
  font-weight: 700;
  margin: 0 0 0.5rem;
  color: hsl(220, 20%, 25%);
}

.wrapper {
  max-width: calc(420px + 10vw);
  margin: 10vh auto 0;
  padding: 5vw;
}

.emoji {
  display: block;
  font-size: 40px;
}

h1 {
  font-size: 2rem;
  font-family: limelight, serif;
  text-align: center;
}
.subheading {
  font-style: italic;
  margin: 0 0 3rem;
  text-align: center;
}
h2 {
  font-size: 1.2rem;
}


.movie {
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 1rem;
  margin: 0 0 4rem;
}

.movie .thumb {
  background: #eee;
  width: 100%;
  max-width: 420px;
}

footer {
  margin: 8rem 0 0;
  text-align: center;
  font-size: 0.85rem;
  color: #999;
}
footer a {
  color: inherit;
}
